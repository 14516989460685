import request from '@/utils/request';

const PATH = '/user-bookmark';

export const getBookmarkList = (params: any) => request({
  url: PATH,
  method: 'get',
  params,
});

export const getBookmarkListAll = () => request({
  url: `${PATH}/all`,
  method: 'get',
});

export const addBookmark = (uid: string) => request({
  url: PATH,
  method: 'post',
  data: {
    postUid: uid,
  },
});

export const deleteBookmark = (postUid: string) => request({
  url: `${PATH}/${postUid}`,
  method: 'delete',
});
