
import { IBoard } from '@/types';
import { IBoardCategory } from '@/types/boardCategory';
import Board from '@/views/homepage/board.vue';
import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';

@Component({
  name: 'BoardSearch',
})
export default class extends Vue {
  @Prop({ required: true }) private boardSkin!: string

  @Prop({ required: true }) private fieldList!: any[]

  @Prop({ required: true }) private categoryList!: any[]

  private board: IBoard | null = null;

  private searchFieldList: any[] = [];

  private boardDate = false;

  private class ='';

  private search = {
    searchType: 'all',
    searchValue: '',
    startDate: '',
    endDate: '',
    categoryList: [],
    startField: '',
    endField: '',
  };

  @Watch('fieldList')
  private handleChangeMenu() {
    this.init();
    this.search = {
      searchType: 'all',
      searchValue: '',
      startDate: '',
      endDate: '',
      categoryList: [],
      startField: '',
      endField: '',
    };
  }

  mounted() {
    this.init();
  }

  private init() {
    if (this.$route.query) {
      this.search = {
        ...this.search,
        ...this.$route.query,
      };
      if (this.$route.query.categoryList && typeof (this.$route.query.categoryList) === 'string') {
        this.search.categoryList = (this.$route.query.categoryList as any).split(',');
      }
    }
    if (this.fieldList) {
      this.searchFieldList = this.fieldList.filter((field: any) => {
        const searchField = field.searchState;
        return searchField;
      });
    }
  }

  private filterSearchType(type: string) {
    let rt = false;
    this.fieldList.forEach((flist: any) => {
      if (flist.fieldType.typeCode === type) rt = true;
    });
    return rt;
  }

  private searchReset() {
    this.search = {
      searchType: 'all',
      searchValue: '',
      startDate: '',
      endDate: '',
      categoryList: [],
      startField: '',
      endField: '',
    };
    this.$emit('search', this.search);
  }

  private handleSearch() {
    if (this.fieldList.length > 4) {
      this.search = {
        ...this.search,
        startField: (this.fieldList[3] as any).uid,
        endField: (this.fieldList[4] as any).uid,
      };
    }
    this.$emit('search', this.search);
  }

  private handleClickAll() {
    setTimeout(() => {
      if ((this.search.categoryList as string[]).indexOf('all') < 0) {
        this.search.categoryList = [];
        this.handleSearch();
      } else {
        this.categoryList.forEach((category: any) => {
          category.boardUseCategoryPk.category.children.forEach((c: IBoardCategory) => {
            (this.search.categoryList as string[]).push(c.uid);
          });
        });
        setTimeout(() => {
          this.handleSearch();
        }, 300);
      }
    }, 100);
  }

  private handleClickCategory() {
    setTimeout(() => {
      if (this.categoryList.length <= this.search.categoryList.length && (this.search.categoryList as string[]).indexOf('all') > -1) {
        const allIndex = (this.search.categoryList as string[]).indexOf('all');
        this.search.categoryList.splice(allIndex, 1);
      }
      this.handleSearch();
    }, 300);
  }

  private setClass() {
    this.class = 'sub1_3';
  }

  private getIconType(Uid: string) {
    switch (Uid) {
      // 행사정보 아이콘
      case '45a313c6-39b2-4880-b959-7ea081858fba':
        return '1';
      case 'c48202e0-e711-4aa0-85f3-1b4577fd61f3':
        return '2';
      case '259b1f87-f856-48ed-afd8-fa0ad7a4992b':
        return '3';
      case '999226c5-50d3-4388-8b4c-5fadde9b59c5':
        return '4';
      case '49af700e-c974-435a-98f2-ea07dcaab5dd':
        return '5';
      // 사업안내 아이콘
      case '327a26be-6861-4570-a4ad-54b4a9ba00ad':
        return '6';
      case 'd97aa36c-41ba-40ba-86e2-228fdd6e80fb':
        return '7';
      case '224e5ccf-fcd8-4395-840f-868e63ab41f6':
        return '8';
      case '4e48e473-2792-4483-91ab-9c1ed1000be5':
        return '9';
      case '0dbb1077-4589-4452-b4d9-3d7562996db0':
        return '10';
      // 멘토 아이콘
      case '7edf4426-633c-4d25-9c7e-20c4e17b35f1':
        return '11';
      case '9d01bbb3-09e3-403b-9575-7ae3388927b7':
        return '12';
      case '84c42ba4-bc9d-4e16-bb2c-062176ea0627':
        return '13';
      case 'd6419a35-7fbb-4852-ba6f-55ee1e0d1b1f':
        return '14';
      case '468f33e9-7bce-4b94-ab45-5a5c1b56893b':
        return '15';
      case '69af2aa9-b482-4cc0-8efe-bb35edce7351':
        return '16';
      case '34587d05-d717-40ac-b739-8b055f2a8204':
        return '17';
      case '7a90ea32-2bfd-4412-bdd9-607457706080':
        return '18';

      default:
        return 0;
    }
  }
}
