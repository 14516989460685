
import { UserModule } from '@/store/user';
import { IPost, IBoard } from '@/types';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'BoardPhotoList',
})
export default class extends Vue {
  @Prop({ required: true }) private postList!: IPost[]

  @Prop({ required: true }) private listQuery!: any

  @Prop({ default: 0 }) private listTotal!: number

  @Prop({ required: true }) private board!: IBoard

  private progressIndex = 0;

  private getPostNumber(index: number) {
    let totalSize = (this.listQuery.page) * this.listQuery.size;
    if (totalSize < 0) totalSize = 0;
    return this.listTotal - totalSize - index;
  }

  private handleClickProgress(index: number) {
    if (this.progressIndex !== index) {
      this.progressIndex = index;
      this.$emit('changeProgress', index);
    }
  }

  private getWriteAuth() {
    let hasWriteAuth = false;
    const userRoles = UserModule.roles;
    if (this.board.authWrite === 'GUEST') return true;
    if (userRoles.indexOf('ROLE_ADMIN') > -1) return true;
    userRoles.forEach((role: string) => {
      if (this.board.writeRoles.indexOf(role) > -1) hasWriteAuth = true;
    });
    return hasWriteAuth;
  }

  private formatChange(str: string) {
    if (str.length > 13) {
      return '-';
    }

    return str;
  }
}
