
import { UserModule } from '@/store/user';
import { IBoard, IPost, IPostFile } from '@/types';
import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';
import { addBookmark, deleteBookmark, getBookmarkListAll } from '@/api/userBookmark';

@Component({
  name: 'BoardPhotoList',
})
export default class extends Vue {
  @Prop({ required: true }) private board!: IBoard

  @Prop({ required: true }) private postList!: IPost[]

  @Prop({ required: true }) private listQuery!: any

  @Prop({ default: 0 }) private listTotal!: number

  mounted() {
    if (this.board.favoriteState && this.isLogined) this.getBookmarks();
    if (this.$route.query.progress) {
      this.progressIndex = Number(this.$route.query.progress);
    }
  }

  get isLogined() {
    return UserModule.token;
  }

  private progressIndex = 0;

  private bookmarks: any = [];

  private sort = 'createDate';

  private getBookmarks() {
    getBookmarkListAll().then((res) => {
      this.bookmarks = res.data;
    });
  }

  private isBookmark(postUid: string) {
    return this.bookmarks.indexOf(postUid) > -1;
  }

  private handleAddBookmark(postUid: string) {
    addBookmark(postUid).then(() => {
      this.bookmarks.push(postUid);
    });
  }

  private handleDeleteBookmark(postUid: string) {
    deleteBookmark(postUid).then(() => {
      this.bookmarks.splice(this.bookmarks.indexOf(postUid), 1);
    });
  }

  private getWriteAuth() {
    let hasWriteAuth = false;
    const userRoles = UserModule.roles;
    if (userRoles.indexOf('ROLE_ADMIN') > -1) return true;
    userRoles.forEach((role: string) => {
      if (this.board.writeRoles.indexOf(role) > -1) hasWriteAuth = true;
    });
    return hasWriteAuth;
  }

  private getPostNumber(index: number) {
    let totalSize = (this.listQuery.page - 1) * this.listQuery.size;
    if (totalSize < 0) totalSize = 0;
    return this.listTotal - totalSize - index;
  }

  private isEnd(post: IPost) {
    if (!post.dataList[3].inputValue || !post.dataList[4].inputValue) return '진행중';
    const today = moment(moment().format('YYYY-MM-DD 00:00:00'));
    const endDay = moment(post.dataList[4].inputValue, 'YYYY-MM-DD');
    const remain = endDay.diff(today, 'days');
    if (remain >= 1) return `마감 ${remain} 일전`;
    if (remain >= 0) return '오늘 마감';
    return '마감';
  }

  private isEndClass(post: IPost) {
    if (!post.dataList[4].inputValue) return 'state01';
    const today = moment();
    const endDay = moment(post.dataList[4].inputValue, 'YYYY-MM-DD');
    const remain = endDay.diff(today, 'days');
    if (remain >= 30) return 'state03';
    if (remain >= 7) return 'state02';
    if (remain >= 0) return 'state01';
    return 'state00';
  }

  private handleClickProgress(index: number) {
    if (this.progressIndex !== index) {
      this.progressIndex = index;
      this.$emit('changeProgress', index);
    }
  }

  private handleChangeSort() {
    this.$emit('changeSort', this.sort);
  }
}
