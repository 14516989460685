
import { UserModule } from '@/store/user';
import { IPost, IBoard } from '@/types';
import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';

@Component({
  name: 'BoardPhotoList',
})
export default class extends Vue {
  @Prop({ required: true }) private postList!: IPost[]

  @Prop({ required: true }) private listQuery!: any

  @Prop({ default: 0 }) private listTotal!: number

  @Prop({ required: true }) private board!: IBoard

  get imagePath() {
    return `${process.env.VUE_APP_COMMON_API}/attached-file`;
  }

  private userId = UserModule.userId;

  private mentor_name = '';

  private progressIndex = 0;

  private getWriteAuth() {
    let hasWriteAuth = false;
    const userRoles = UserModule.roles;
    if (userRoles.indexOf('ROLE_ADMIN') > -1) return true;
    userRoles.forEach((role: string) => {
      if (this.board.writeRoles.indexOf(role) > -1) hasWriteAuth = true;
    });
    return hasWriteAuth;
  }

  private isEnd(post: IPost) {
    if (!post.dataList[3].inputValue || !post.dataList[4].inputValue) return false;
    const today = moment();
    const startDay = moment(post.dataList[3].inputValue, 'YYYY-MM-DD');
    const endDay = moment(post.dataList[4].inputValue, 'YYYY-MM-DD').hours(23).minutes(59).seconds(59);
    return today.isSameOrAfter(startDay) && today.isSameOrBefore(endDay);
  }

  private handleClickProgress(index: number) {
    if (this.progressIndex !== index) {
      this.progressIndex = index;
      this.$emit('changeProgress', index);
    }
  }

  private getThumnail(inputValue: string) {
    if (inputValue.startsWith('http')) return inputValue;
    return `${this.imagePath}/${inputValue}`;
  }

  private sliceName(name: string) {
    return name.split(' 멘토')[0];
  }
}
