
import { UserModule } from '@/store/user';
import { IBoard, IPost, IPostFile } from '@/types';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'BoardPhotoList',
})
export default class extends Vue {
  @Prop({ required: true }) private board!: IBoard

  @Prop({ required: true }) private postList!: IPost[]

  @Prop({ required: true }) private listQuery!: any

  @Prop({ default: 0 }) private listTotal!: number

  get imagePath() {
    return `${process.env.VUE_APP_COMMON_API}/attached-file`;
  }

  private getWriteAuth() {
    let hasWriteAuth = false;
    const userRoles = UserModule.roles;
    if (userRoles.indexOf('ROLE_ADMIN') > -1) return true;
    userRoles.forEach((role: string) => {
      if (this.board.writeRoles.indexOf(role) > -1) hasWriteAuth = true;
    });
    return hasWriteAuth;
  }

  private getThumnail(inputValue: string) {
    if (inputValue.startsWith('http')) return inputValue;
    return `${this.imagePath}/${inputValue}`;
  }
}
